<template>
    <div :style="isMobile()?{height: '75vh'}:{height: '97vh'}" style="overflow-y: auto;">
        <el-row style="display: flex; align-items: center; background-color: #17484C; ">
            <el-col :xl="8" :lg="8" :md="8" :sm="24" :xs="24" @click.native="$router.go(-1)" class="component-title"
                    style="cursor: pointer;">
                <back-arrow></back-arrow>
                My Account
            </el-col>
            <el-col :offset="14" :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-row>
            <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" style="padding: 0 10px;">
                <el-card class="profileDetailsCard" style=""
                         :style="!isMobile() ? {'margin-top': '5vh', 'padding': '50px 20px'}:{'margin-top': '2vh', 'padding': '20px'}">
                    <div slot="header"
                         style="padding: 0 !important;"
                         class="changeProfileHeader">
                        <el-row>
                            <div style="float: left;">
                                Basic Details
                            </div>
                            <div style="float: right;" v-if="!updateDetailsFlag">
                                <el-button @click.native="updateDetailsFlag = true" style="padding: 0 !important;"
                                           type="text">
                                    Edit
                                </el-button>
                            </div>
                            <div style="float: right;" v-else>
                                <el-button @click="updateDetailsFlag = false"
                                           style="padding: 0 !important;" type="text">
                                    Cancel
                                </el-button>
                            </div>
                        </el-row>
                    </div>
                    <el-form class="profileClass" :label-position="'top'" label-width="25vw" style="margin-top: 3vh;">
                        <el-form-item label="First Name">
                            <el-input :disabled="!updateDetailsFlag" v-model="firstName"></el-input>
                        </el-form-item>
                        <el-form-item label="Last Name">
                            <el-input :disabled="!updateDetailsFlag" v-model="lastName"></el-input>
                        </el-form-item>
                        <el-form-item label="Email">
                            <!--                            <el-input :disabled="!updateDetailsFlag" v-model="email"></el-input>-->
                            <div class="el-input__inner" style="border: none !important; text-align: left;">
                                {{$store.getters.getStudentEmail}}
                            </div>
                        </el-form-item>
                        <el-form-item v-if="!getIsCounsellor" label="School Name">
                            <el-select :disabled="!updateDetailsFlag" filterable placeholder="Select School"
                                       style="width: 100%"
                                       v-model="schoolName">
                                <el-option
                                        :key="index"
                                        :label="item.value"
                                        :value="item.value"
                                        v-for="(item, index) in schools">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="Current School Year">
                            <el-input :disabled="!updateDetailsFlag"
                                      v-model="currentSchoolYear"></el-input>
                        </el-form-item>
                        <el-form-item v-if="!getIsCounsellor" label="College Name">
                            <el-select :disabled="!updateDetailsFlag" filterable placeholder="Select College"
                                       style="width: 100%"
                                       v-model="favouriteCollege">
                                <el-option
                                        :key="index"
                                        :label="item.value"
                                        :value="item.value"
                                        v-for="(item, index) in colleges">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-form>
                    <el-button v-if="updateDetailsFlag" @click="updateUserProfileDetails">Update</el-button>
                </el-card>
            </el-col>
            <el-col :xl="12" :lg="12" :md="12" :sm="24" :xs="24" style="padding: 0 10px;">
                <el-row>
                    <el-card style="padding: 50px 20px;" class="profileDetailsCard"
                             :style="!isMobile() ? {'margin-top': '5vh', 'padding': '50px 20px'}:{'margin-top': '2vh', 'padding': '20px'}">
                        <div slot="header"
                             style="padding: 0 !important;"
                             class="changeProfileHeader">
                            <el-row>
                                <div style="float: left;">
                                    Change Password
                                </div>
                                <div style="float: right;" v-if="!updatePassFlag">
                                    <el-button @click.native="updatePassFlag = true" style="padding: 0 !important;"
                                               type="text">
                                        Update
                                    </el-button>
                                </div>
                                <div style="float: right;" v-else>
                                    <el-button @click="updatePassFlag = false"
                                               style="padding: 0 !important;" type="text">
                                        Cancel
                                    </el-button>
                                </div>
                            </el-row>
                        </div>
                        <el-form class="profileClass" :label-position="'top'" label-width="150px"
                                 style="margin-top: 3vh;">
                            <el-form-item label="Password" v-if="!updatePassFlag">
                                <el-input disabled placeholder="********"></el-input>
                            </el-form-item>

                            <el-form-item label="Old Password" v-if="updatePassFlag" show-password>
                                <el-input type="password" v-model="oldPassword"></el-input>
                            </el-form-item>
                            <el-form-item label="New Password" v-if="updatePassFlag" show-password>
                                <el-input type="password" v-model="newPassword"></el-input>
                            </el-form-item>
                            <el-form-item label="Confirm Password" v-if="updatePassFlag" show-password>
                                <el-input type="password" v-model="newPasswordConfirm"></el-input>
                            </el-form-item>
                        </el-form>
                        <el-button v-if="updatePassFlag" @click="changePassword">Update</el-button>
                        <ul>
                            <li v-for="(error, index) in passwordErrors" :key="index"
                                style="color: red; font-size: 10px; text-align: left !important;">
                                {{error}}
                            </li>
                        </ul>
                    </el-card>
                </el-row>
                <el-row v-if="!getIsCounsellor && !getIsAdmin">
                    <el-card style="padding: 50px 20px;"
                             :style="!isMobile() ? {'margin-top': '3vh', 'padding': '50px 20px'}:{'margin-top': '2vh', 'padding': '20px'}">
                        <el-form class="profileClass" :label-position="'top'" label-width="150px"
                                 style="margin-top: 3vh;">
                            <el-form-item>
                                <el-row style="margin: 1vh 0;" class="school-college-label">
                                    Do you give permission for following Guidance Counsellors to
                                    access your results and Skillsvista account information for guidance purposes? (this does
                                    not
                                    include email address or account password)
                                </el-row>
                            </el-form-item>
                            <el-form-item label="School Counsellor">
                                <el-radio-group @change="updateGuidancePermission"
                                                v-model="schoolCounsellorAccess">
                                    <el-radio :label="'Yes'">Yes</el-radio>
                                    <el-radio :label="'No'">No</el-radio>
                                </el-radio-group>
                            </el-form-item>

                            <el-form-item label="Private Counsellor">
                                <el-radio-group @change="updateGuidancePermission"
                                                v-model="privateCounsellorAccess">
                                    <el-radio :label="'Yes'">Yes</el-radio>
                                    <el-radio :label="'No'">No</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-form>
                    </el-card>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
  import BackArrow from '../../main_components/BackArrow';
  import ProfileAvatar from '../../main_components/ProfileAvatar';

  export default {
    name: 'ProfileDetails',
    components: {ProfileAvatar, BackArrow},
    mounted() {
      let self = this;
      self.$store.state.loadingScreen = true;
      self.getUserInfo().then(response => {
        self.listColleges().then(response1 => {
          self.colleges = response1.map(function(value) {
            return {'value': value['name']};
          });
          self.listSchools().then(response => {
            self.schools = response;
            self.$store.state.loadingScreen = false;
          });
          self.firstName = self.$store.getters.getFirstName;
          self.lastName = self.$store.getters.getLastName;
          // console.log(self.$store.getters.getPrivateCounsellorAccess);
          // console.log(self.$store.getters.getFavouriteCollege);
          self.privateCounsellorAccess = self.$store.getters.getPrivateCounsellorAccess;
          self.schoolCounsellorAccess = self.$store.getters.getSchoolCounsellorAccess;
          self.favouriteCollege = self.$store.getters.getFavouriteCollege;
          self.schoolName = self.$store.getters.getSchoolName;
          self.currentSchoolYear = self.$store.getters.getCurrentSchoolYear;
        }).catch(error => {
          console.log(error);
        });
      });
    },
    data() {
      return {
        firstName: '',
        lastName: '',
        privateCounsellorAccess: 'Yes',
        schoolCounsellorAccess: 'Yes',
        favouriteCollege: '',
        schoolName: '',
        currentSchoolYear: '',
        updatePassFlag: false,
        newPassword: '',
        newPasswordConfirm: '',
        oldPassword: '',
        userId: false,
        schools: false,
        colleges: false,
        loadingScreen: false,
        labelPosition: 'left',
        email: '',
        passwordErrors: [],
        updateDetailsFlag: false,
      };
    },
    methods: {
      updateGuidancePermission: function() {
        let self = this;
        let url = `${self.getBaseUrl()}/accounts/counsellor/access/update/`;
        self.$store.state.loadingScreen = true;
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          url: url,
          method: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            'school_counsellor_access': self.schoolCounsellorAccess,
            'private_guidance_counsellor': self.privateCounsellorAccess,
          }),
          success: function(response) {
            self.$store.state.loadingScreen = false;
          },
          error: function(error) {
            self.$store.state.loadingScreen = false;
          },
        });
      },
      updateUserProfileDetails: function() {
        let self = this;
        let url = `${self.getBaseUrl()}/accounts/user/update/${self.$store.getters.getUserId}/`;
        self.$store.state.loadingScreen = true;
        $.ajax({
          url: url,
          method: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify({
            first_name: self.firstName,
            last_name: self.lastName,
            college_name: self.favouriteCollege,
            school_name: self.schoolName,
            current_year: self.currentSchoolYear,
          }),
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          success: function(response) {
            self.$store.state.loadingScreen = false;
            self.updateDetailsFlag = false;
            self.passwordErrors = [];
            self.$message({
              title: 'Successful',
              message: 'Profile details updated successfully.',
              type: 'success',
              duration: 3000,
              showClose: true,
            });
          },
          error: function(error) {
            self.$store.state.loadingScreen = false;
            self.passwordErrors = [];
            self.$message({
              title: 'Successful',
              message: 'Error while updating profile details.',
              type: 'error',
              duration: 3000,
              showClose: true,
            });
          },
        });
      },
      changePassword: function() {
        let self = this;
        let valid = true;
        if (self.oldPassword.trim() === '') {
          self.passwordErrors.push('Please enter old password');
          valid = false;
        }
        if (self.newPassword.trim() === '') {
          self.passwordErrors.push('Please enter new password');
          valid = false;
        }
        if (self.newPassword.trim() !== self.newPasswordConfirm.trim()) {
          self.passwordErrors.push('New passwords does not match');
          valid = false;
        }
        if (!valid) {
          return false;
        }

        let url = `${self.getBaseUrl()}/accounts/change_password/${self.$store.getters.getUserId}/`;
        self.$store.state.loadingScreen = true;
        $.ajax({
          url: url,
          method: 'PUT',
          contentType: 'application/json',
          data: JSON.stringify({old_password: self.oldPassword, new_password: self.newPassword}),
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          success: function(response) {
            self.passwordErrors = [];
            self.$store.state.loadingScreen = false;
            self.$message({
              title: 'Successful',
              message: 'Password updated successfully.',
              type: 'success',
              duration: 3000,
              showClose: true,
            });
            self.newPassword = '';
            self.newPasswordConfirm = '';
            self.oldPassword = '';
            self.updatePassFlag = false;
          },
          error: function(error) {
            self.$store.state.loadingScreen = false;
            self.$message({
              title: 'Unsuccessful',
              message: 'Cannot change the password',
              type: 'error',
              duration: 3000,
              showClose: true,
            });
            // self.updatePassFlag = false;
            console.log(error);
            self.passwordErrors = error.responseJSON;
          },
        });
      },
    },
  };
</script>

<style scoped>

    .profileDetailsCard >>> .el-card__header {
        padding-left: 0 !important;
    }
</style>
